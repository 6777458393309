import './shopping_cart_item.css';
import { SERVER_URL } from '../../../config/constants';
import { useCart } from '../../../hooks/use_cart';
import { formatCurrency } from '../../../services/utils';

const ShoppingCartItem = ({ product }) => {
    const { addQuantity, removeQuantity, removeProduct, setQuantity } = useCart();

    function changeAmmountVal(e) {
        if (e.target.value < 1) {
            e.target.value = 1;
        }

        setQuantity(product);
    }

    return (
        <div id='cart-product'>
            <div id='product-deatils'>
                <div id='product-info-container'>
                    <img src='../x.png' width={"20px"} height="20px" onClick={() => removeProduct(product)}></img>
                    <div id='image-background'>
                        <img src={`/products/${product.images[0]}.png`}></img>
                    </div>
                    <div id='product-data'>
                        <p><b>{product.name}</b></p>
                        <p>{product.size}</p>
                        <p>{product.color}</p>
                    </div>
                </div>
                <div id='ammount-container'>
                    <p><b>КОЛИЧИНА:</b></p>
                    <div id='ammount-selector'>
                        <button onClick={() => removeQuantity(product)}>-</button>
                        <input type='number' min={'1'} value={product.quantity}></input>
                        <button onClick={() => addQuantity(product)}>+</button>
                    </div>
                </div>
                <div id='price-div'>
                    <p><b>ЦЕНА:</b></p>
                    <p>{product.price}</p>
                </div>
                <div id='total-sum'>
                    <p><b>УКУПНО:</b></p>
                    <p>{formatCurrency(parseInt(product.price) * parseInt(product.quantity))}</p>
                </div>
            </div>
        </div>
    )
}

export default ShoppingCartItem