import { useLocation } from 'react-router-dom';
import './products_display.css';
import Loader from '../../common/loader/loader';
import { useProducts } from '../../../hooks/use_products';
import DisplayProductCard from '../../common/display_product_card/display_product_card';

const ProductsDisplay = (props) => {
    const { products } = useProducts(props.index);
    const location = useLocation()

    function getDisplayCategory() {
        let path = location.pathname.split('/')[1]

        switch (path) {
            case 'muskarci':
                return 'МУШКАРЦИ'
            case 'zene':
                return 'ЖЕНЕ'
            case 'deca':
                return 'ДЕЦА'
            case 'ottodivision':
                return 'OTTO-DIVISION'
        }
    }

    if (products == null) {
        return (
            <div id='loader-containerr'>
                <Loader elHeight="150px"></Loader>
            </div>
        )
    } else if (products.length > 0) {
        return (
            < div id='show-product-container' >
                <div id='category-title'>
                    <p>{getDisplayCategory()}</p>
                </div>
                <div id='products-wrapper' style={{ height: products.length > 0 ? 'auto' : '400px' }}>
                    {
                        products.length > 0 ?
                            products.map((product, index) => {
                                return <DisplayProductCard key={index} productProps={product} />
                            })
                            :
                            <p>НЕМА ПРОИЗВОДА ЗА ОВУ КАТЕГОРИЈУ</p>
                    }
                </div>
            </div >
        )
    } else {
        return (
            <div id='show-product-container'>
                <div id='category-title'>
                    <p>{getDisplayCategory()}</p>
                </div>
                <div style={{ height: "400px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <p style={{ fontSize: "2vw" }}>НЕМА ПРОИЗВОДА ЗА ОВУ КАТЕГОРИЈУ</p>
                </div>
            </div >
        )
    }
}

export default ProductsDisplay