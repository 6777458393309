import { useContext, useEffect } from 'react'
import MobileMenu from '../mobile_side_menu/mobile_side_menu'
import { useNavigate } from 'react-router-dom'
import DataContext from '../../../context/data_context'
import AnchorLink from '../../common/anchor_link/anchor_link'
import SquareLink from '../../common/square-link/square-link'
import './header.css'

const Header = () => {
    const { loggedIn, setLoggedIn } = useContext(DataContext)
    const { data, setData } = useContext(DataContext)
    const navigate = useNavigate()

    function showMobileMenu() {
        var menu = document.getElementById("mobileMenuContainerHide")
        menu.setAttribute("id", "mobileMenuContainerShow")
    }

    function showLoginDialog() {
        if (!loggedIn) {
            var loginDialog = document.getElementById("hidelogindialog");
            loginDialog.setAttribute("id", "showlogindialog");
        }
        else {
            navigate('/nalog-korisnika/profil')
        }
    }

    return (
        <>
            <header>
                <div id='top-stripe'>
                    <div id='top-stripe-container'>
                        <div id='top-stripe-socials'>
                            <a href={'https://www.instagram.com/ottoshoprs/'} target='_blank'><img src={'/instagram.png'} width="inherit"></img></a>
                        </div>
                        <div id='top-stripe-message'>
                            <p>БЕСПЛАТНА ДОСТАВА ЗА ПОРУЏБИНЕ ПРЕКО 5000,00 РСД</p>
                        </div>

                    </div>
                </div>
                <div id='main-header'>
                    <div id='main-header-container'>
                        <div id='main-header-left'>
                            <SquareLink cssid={'header-left-logo'} cl={'none'} ishref={true} isButton={false} path={'/'} imgsrc={'/logo.png'}></SquareLink>
                        </div>
                        <div id='main-header-center'>
                            <AnchorLink text={"МУШКАРЦИ"} path={'/muskarci'} boldText={false}></AnchorLink>
                            <AnchorLink text={"ЖЕНЕ"} path={'/zene'} boldText={false}></AnchorLink>
                            <AnchorLink text={"ДЕЦА"} path={'/deca'} boldText={false}></AnchorLink>
                            <AnchorLink text={"OTTO-DIVISION"} path={'/ottodivision'} boldText={true}></AnchorLink>
                        </div>
                        <div id='main-header-right'>
                            {/* <SquareLink cssid={'header-right-search'} isButton={false} imgsrc={'/search.png'}></SquareLink> */}
                            <SquareLink cssid={'header-right-cart'} path={'/korpa'} isButton={false} isCart={true} imgsrc={'/shopping-cart.png'}></SquareLink>
                            {/* <SquareLink cssid={'header-right-user'} clickFunction={showLoginDialog} pathFunc={showLoginDialog} isButton={false} imgsrc={'/user.png'}></SquareLink> */}
                            <SquareLink cssid={'header-left-menu-mb'} cl={'mb'} isButton={false} clickFunction={showMobileMenu} imgsrc={'/menu-short.png'}></SquareLink>
                        </div>
                    </div>
                </div>
            </header>
            <MobileMenu></MobileMenu>
        </>
    )
}

export default Header