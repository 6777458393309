import { useEffect, useState } from "react";
import { SERVER_URL } from "../config/constants";
import { fetchService } from "../services/fetch_service";

export const useBanners = () => {
    const [banners, setBanners] = useState([]);
    const [banner, setBanner] = useState(null);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (!checkLocalStorage()) fetchBanners();
    }, []);

    useEffect(() => {
        if (banners.length > 1) {
            setTimeout(() => {
                next();
            }, 5000);
        }
    }, [banners, index]);

    const checkLocalStorage = () => {
        const bannersData = localStorage.getItem('banners');
        if (bannersData) {
            try {
                const banners = JSON.parse(bannersData);

                if (Array.isArray(banners) && banners.length > 0) {
                    if (localStorage.getItem('banners_date') !== getDateString()) {
                        fetchBanners();
                        return false;
                    }

                    setBanners(banners);
                    return true;
                }
            } catch (e) {
                setBanners([]);
                setBanner(null);
                return false;
            }
        }
    };

    const fetchBanners = async () => {
        try {
            const res = await fetchService().get(`${SERVER_URL}/get_banner_images`);
            if (res.status === 200) {
                setBanners(res.data);
                localStorage.setItem('banners', JSON.stringify(res.data));
                localStorage.setItem('banners_date', getDateString());
            }
        } catch (e) {

        }
    };

    const getDateString = () => {
        const date = new Date();
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}.`;
    };

    const next = () => {
        if (index + 1 == banners.length) setIndex(0);
        else setIndex(index + 1);
    };

    const previous = () => {
        if (index > 0) setIndex(index - 1);
        else setIndex(banners.length - 1);
    };

    useEffect(() => {
        change();
    }, [index]);

    useEffect(() => {
        if (banners.length == 0) return;
        if (window.innerWidth > 1149) {
            if (banner == null) setBanner([`/${banners[index].path1}`, banners[index].link]);
        }
        else if (window.innerWidth <= 1149 && window.innerWidth > 601) {
            if (banner == null) setBanner([`/${banners[index].path2}`, banners[index].link]);
        }
        else if (window.innerWidth <= 600) {
            if (banner == null) setBanner([`/${banners[index].path3}`, banners[index].link]);
        }

        window.addEventListener('resize', change)

        return () => {
            window.removeEventListener('resize', change)
        }

    }, [banners, banner]);

    function change() {
        if (banners.length == 0) return;
        if (banner == null) return;
        if (window.innerWidth > 1149) {
            if (banner[0] != `/${banners[index].path1}`) {
                setBanner([`/${banners[index].path1}`, banners[index].link]);
            }
        }
        else if (window.innerWidth <= 1149 && window.innerWidth > 601) {
            if (banner[0] != `/${banners[index].path2}`) {
                setBanner([`/${banners[index].path2}`, banners[index].link]);
            }
        }
        else if (window.innerWidth <= 600) {
            if (banner[0] != `/${banners[index].path3}`) {
                setBanner([`/${banners[index].path3}`, banners[index].link]);
            }
        }
    }

    return [banner ?? null, index, next, previous, banners.length];
};