import './ordering.css';
import '../../../config/global_style.css'
import { useEffect, useRef, useState } from 'react';
import { useCaptcha } from '../../../hooks/use_captcha';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../../hooks/use_cart';
import { fetchService } from '../../../services/fetch_service';
import { SERVER_URL } from '../../../config/constants';
import { formatCurrency } from '../../../services/utils';
import Loader from '../../common/loader/loader';

const Ordering = () => {
    const [formValues, setFormValues] = useState({
        name: '',
        email: '',
        adress: '',
        city: '',
        phone: '',
        code: ''
    });
    const [captcha, refreshCaptcha] = useCaptcha();
    const { cart, data, applyCoupon, emptyCart } = useCart();
    const [coupon, setCoupon] = useState('');
    const [couponError, setCouponError] = useState('');
    const [couponValid, setCouponValid] = useState(false);
    const [couponData, setCouponData] = useState(null);
    const [progress, setProgress] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState("");

    const navigate = useNavigate()

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormValues((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    async function handleCoupon(value) {
        setCouponError('');
        setCouponValid(false);
        setCouponData(null);
        setCoupon(value);
        applyCoupon(null);
    }

    useEffect(() => {
        if (!coupon || coupon == "") return;
        let timer = setTimeout(async () => {
            let res = await fetchService().get(`${SERVER_URL}/check_coupon/${coupon}`);

            if (res.status != 200) {
                setCouponError(res.message)
                return
            }

            setCouponValid(true);
            setCouponData(res.data);
            applyCoupon(res.data.discount);
        }, 1000);

        return () => clearTimeout(timer);
    }, [coupon]);

    async function order() {
        try {
            setProgress(true);
            setError('');

            if (!formValues.name || !formValues.email || !formValues.adress || !formValues.city || !formValues.phone) {
                alert('Молимо вас попsdsуните сва поља');
                setProgress(false);
                return;
            }

            let res = await fetchService().post(`${SERVER_URL}/order`, {
                ...formValues,
                token: captcha.token,
                cart: cart,
                coupon: coupon ? coupon : ""
            });

            if (res.status != 200) {
                setProgress(false);
                setError(res.message);
                refreshCaptcha();
                return;
            }

            emptyCart();
            setSuccess(res.message);
            setError('');
            setProgress(false);

            setTimeout(() => {
                navigate('/');
            }, 4000);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <div id='order-container'>
                <div className='content-cont'>
                    <p>НАРУЧИВАЊЕ</p>
                </div>
                <div id='second-container'>
                    <div id='order-form-container'>
                        <label>Ваше пуно име:</label>
                        <input type='text' placeholder='Ваше пуно име' name='name' onChange={handleInputChange} value={formValues['name']} class="input-login-dialog"></input>
                        <label>Ваш емаил:</label>
                        <input type='email' placeholder='Ваш емаил' name='email' onChange={handleInputChange} value={formValues['email']} class="input-login-dialog"></input>
                        <label>Ваш телефон:</label>
                        <input type='text' placeholder='Ваш телефон' name='phone' onChange={handleInputChange} value={formValues['phone']} class="input-login-dialog"></input>
                        <label>Ваше место становања:</label>
                        <input type='text' placeholder='Вашa adresa' name='city' onChange={handleInputChange} value={formValues['city']} class="input-login-dialog"></input>
                        <label>Ваша адреса:</label>
                        <input type='text' placeholder='Вашa adresa' name='adress' onChange={handleInputChange} value={formValues['adress']} class="input-login-dialog"></input>
                        <div id={"captcha-container"}>
                            <img src={captcha.image} alt={"captcha"} width={"100%"}></img>
                            <p>Унесите код са слике:</p>
                            <input
                                type={"text"}
                                name="code"
                                value={formValues.code}
                                onChange={handleInputChange}
                                class="input-login-dialog"
                                placeholder={"Код*"} />
                        </div>
                        {
                            progress ?
                                <Loader elHeight={"30px"} />
                                :
                                (
                                    success ?
                                        <div style={{
                                            backgroundColor: 'rgba(43, 255, 71, 0.849)',
                                            width: "100%",
                                            boxSizing: "border-box",
                                            padding: "10px",
                                            color: "black",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "10px"
                                        }}>{success}</div>
                                        :
                                        <input className='login-dialog-submit' type='submit' value='ПОРУЧИ' onClick={order}></input>
                                )
                        }
                        {
                            error &&
                            <p style={{ color: 'red' }}>{error}</p>
                        }
                    </div>
                    <div id='order-details'>
                        <p><b>ПРЕГЛЕД ПОРУЏБИНЕ</b></p>
                        <p>Број артикала у каси: {cart.length}</p>
                        <p>Укупно за наплату: <b>{data.total}</b></p>
                        <p>{data.free ? 'Бесплатна достава' : 'Достава се наплаћује'}</p>
                        <p style={{ marginTop: '20px' }}>Код купона за попуст: </p>
                        <input
                            type="text"
                            name="coupon"
                            id="coupon"
                            placeholder='Код купона за попуст'
                            onChange={(e) => handleCoupon(e.target.value)}
                        />
                        {couponError && <p style={{ color: 'red' }}>{couponError}</p>}
                        {couponValid && <p style={{ color: 'green' }}>Код је валидан, умањили сте цену за {formatCurrency(data.discount)}</p>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Ordering