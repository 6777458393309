import './product_stripe_card.css'
import { Link } from 'react-router-dom'
import { useProduct } from '../../../hooks/use_product'
import { SERVER_URL } from '../../../config/constants'
import Loader from '../loader/loader'

const ProductStripeCard = (props) => {
    const { product, image, forward, backwards } = useProduct(props.product);

    if (product == null) {
        return (
            <div id='product-card' className={props.klas}>
                <Loader elHeight={'50px'}></Loader>
            </div>
        )
    } else {
        return (
            <div id='product-card' className={props.klas} onMouseEnter={forward} onMouseLeave={backwards}>
                <Link to={'/proizvod/' + product.url}>
                    <div id='product-image'>
                        <img src={`/products/${image}.png`} width="50px" height="50px"></img>
                        <div id='product-price'>
                            <p>{product.price}</p>
                        </div>
                    </div>
                </Link>
                <div id='product-name'>
                    <p>{product.name}</p>
                </div>
                <div id='product-category'>
                    <p>{product.categories}</p>
                </div>
            </div>
        )
    }
}

export default ProductStripeCard