import CompanyPerk from "../components/ui/company_perks/company_perks"
import Footer from "../components/ui/footer/footer"
import Header from "../components/ui/header/header"
import ShowProducts from "../components/ui/products_display/products_display"
import LoginDialog from "../components/ui/login_user/login_dialog"
import ProductsDisplay from "../components/ui/products_display/products_display"

const DisplayProductsPage = (props) => {
    return (
        <>
            <Header></Header>
            <LoginDialog></LoginDialog>
            <ProductsDisplay index={props.index}></ProductsDisplay>
            <CompanyPerk></CompanyPerk>
            <Footer></Footer>
        </>
    )
}

export default DisplayProductsPage