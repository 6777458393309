import { useContext, useState } from 'react';
import DataContext from '../../../context/data_context';
import './login_dialog.css';
import { Outlet, Link } from 'react-router-dom';
import { fetchService } from '../../../services/fetch_service';
import { SERVER_URL } from '../../../config/constants';
import { useAuth } from '../../../hooks/use_auth';
import '../../../config/global_style.css'
import Loader from '../../common/loader/loader';

const LoginDialog = (props) => {
    const { loggedIn, setLoggedIn, data, setData } = useContext(DataContext)
    const [credentials, setCredentials] = useState({ email: '', password: '' })
    const [isLoginInProgress, SetLoginProgress] = useState(false)
    const [fillFormWarning, setFillFormWarning] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")
    const { insert } = useAuth();

    function closeLoginDialog() {
        if (!loggedIn) {
            var logindialog = document.getElementById('showlogindialog');
            logindialog.setAttribute("id", "hidelogindialog")
        }
    }

    function handleInputChange(event) {
        const { name, value } = event.target
        setCredentials((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    function handleSubmit(event) {
        event.preventDefault()
        if (credentials.email == "" || credentials.password == "") {
            setErrorMessage("Морате попунити оба поља!")
            setFillFormWarning(false)
            return
        }

        SetLoginProgress(true)
        logIn()
    }

    async function logIn() {
        setFillFormWarning(true)
        try {
            const res = await fetchService().post(`${SERVER_URL}/login`, {
                email: credentials.email,
                password: credentials.password
            });

            if (res.status != 200) {
                setErrorMessage(res.message);
                setFillFormWarning(false);
                SetLoginProgress(false);
                return;
            }

            insert('data', res.data);
            insert('jwt', res.data.token);
            setData(res.data);
            SetLoginProgress(false);
            setLoggedIn(true);

            setTimeout(() => {
                closeLoginDialog()
            }, 2000)
        } catch (error) {
            console.log(error)
            setErrorMessage("Дошло је до грешке приликом логовања");
            setFillFormWarning(false);
            SetLoginProgress(false);
        }
    }

    return (
        <>
            <div id='hidelogindialog'>
                <div id="login-dialog-background">

                </div>
                <div id="login-dialog-container">
                    <div id='login-dialog'>
                        <div id='login-dialog-content'>
                            <img src='../x.png' width={"20px"} height="20px" onClick={closeLoginDialog}></img>
                            <h1>УЛОГУЈТЕ СЕ</h1>
                            <div id='form-container'>

                                <label className="form-label">Е-маил:</label>
                                <input className="input-login-dialog" type="email" value={credentials.email} name='email' onChange={(e) => handleInputChange(e)} placeholder='Ваш е-маил'></input>
                                <label className="form-label">Лозинка:</label>
                                <input className="input-login-dialog" type="password" value={credentials.password} name='password' onChange={(e) => handleInputChange(e)} placeholder='Ваша лозинка'></input>
                                <Link to="/resetovanjelozinke" id="anchor-pr"><p class="login-dialog-message">Заборавили сте лозинку?</p></Link>
                                {
                                    isLoginInProgress ?
                                        <Loader elHeight={"20px"}></Loader>
                                        :
                                        (
                                            loggedIn ?
                                                <div id='success'>
                                                    <p>Успешно логовање</p>
                                                </div>
                                                :
                                                <input type="submit" value="Улогуј се" className="login-dialog-submit" onClick={handleSubmit}></input>
                                        )
                                }
                            </div>
                            {!fillFormWarning ?
                                <p className="login-dialog-message" style={{ color: "red", display: "block", marginBottom: "0px", textDecoration: "none" }}>{errorMessage}</p>
                                :
                                <p className="login-dialog-message" style={{ color: "red", display: "none" }}>{errorMessage}</p>
                            }
                            <p className="login-dialog-message" onClick={() => closeLoginDialog}><Link to='/registracija_korisnika' id='no-prof'>Немате профил?</Link></p>
                        </div>

                    </div>
                </div>
            </div>
            <Outlet />
        </>
    )
}

export default LoginDialog