import CompanyPerk from "../components/ui/company_perks/company_perks";
import Footer from "../components/ui/footer/footer";
import Header from "../components/ui/header/header";
import Ordering from "../components/ui/ordering/ordering";
import LoginDialog from "../components/ui/login_user/login_dialog";

const OrderingRoute = () => {
    return (
        <>
            <Header></Header>
            <LoginDialog></LoginDialog>
            <Ordering></Ordering>
            <CompanyPerk></CompanyPerk>
            <Footer></Footer>
        </>
    );
}

export default OrderingRoute