import { Link } from 'react-router-dom'
import './display_product_card.css'
import { useProduct } from '../../../hooks/use_product'
import { SERVER_URL } from '../../../config/constants'
import Loader from '../loader/loader'

const DisplayProductCard = ({ productProps }) => {
    const { product, image, changeImage, stopImage } = useProduct(productProps);

    return (
        product == null ?
            <div id='display-product-container'>
                <Loader elHeight={'30px'}></Loader>
            </div>
            :
            <div
                id='display-product-container'
            >
                <Link to={'/proizvod/' + product.url}>
                    <div id='display-product-image'>
                        <img
                            src={`/products/${image}.png`}
                            loading='lazy'
                            onMouseOver={() => { changeImage() }}
                            onMouseOut={() => { stopImage() }}
                        ></img>
                        <div id='product-price'>
                            {product.price}
                        </div>
                    </div>
                </Link>
                <div id='product-info'>
                    <p id='product-name'>{product.name}</p>
                    <p id='product-category'>{product.categories}</p>
                </div>
            </div>
    )
}

export default DisplayProductCard