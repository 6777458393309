import { useState, useEffect } from 'react';

export const useAuth = () => {
    const [auth, setAuth] = useState({});

    useEffect(() => {
        const data = localStorage.getItem('data');
        const jwt = localStorage.getItem('jwt');

        if (data) {
            setAuth((prev) => ({
                ...prev,
                data: JSON.parse(data),
            }));
        }

        if (jwt) {
            setAuth((prev) => ({
                ...prev,
                jwt: JSON.parse(jwt),
            }));
        }
    }, []);

    const insert = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
        setAuth((prev) => ({
            ...prev,
            [key]: value
        }));
    };

    const remove = (key) => {
        localStorage.removeItem(key);
        setAuth((prev) => {
            const { [key]: _, ...rest } = prev;
            return rest;
        });
    };

    return { auth, insert, remove };
};