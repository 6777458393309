import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import DataContext from './context/data_context';
import { useState } from 'react'
import Info from './pages/info/info';
import DisplayProductsPage from './pages/display_products_page';
import ProductOverviewRoute from './pages/product_overview_page';
import ShoppingCartRoute from './pages/shopping_cart';
import LandingPage from './pages/landing_page';
import OrderingRoute from './pages/ordering_route';

function App() {
  const [data, setData] = useState(0)
  const [loggedIn, setLoggedIn] = useState(() => {
    if (localStorage.getItem("jwt")) {
      var person = JSON.parse(localStorage.getItem("data"))
      setData(person)
      return true
    }
    else {
      return false
    }
  })

  const [cart, setCart] = useState(() => {
    if (localStorage.getItem("cart")) {
      return JSON.parse(localStorage.getItem("cart"))
    }
    return []
  })

  return (
    <DataContext.Provider value={{ loggedIn, setLoggedIn, data, setData, cart, setCart }}>
      <BrowserRouter>
        <Routes>
          <Route index element={<LandingPage></LandingPage>}></Route>
          {/* <Route path='resetovanje_lozinke' element={<ResetPassword></ResetPassword>}></Route>
          <Route path='registracija_korisnika' element={<RegisterUserPage></RegisterUserPage>}></Route>
          <Route path='potvrdi_nalog' element={<ConfirmAccountRoute></ConfirmAccountRoute>}></Route> */}
          <Route path="informacije/:route" element={<Info></Info>} />
          {/* <Route path="nalog-korisnika/profil" element={<UserProfileRoute></UserProfileRoute>} />
          <Route path="nalog-korisnika/porudzbine" element={<UserOrders></UserOrders>}></Route> */}
          <Route path="muskarci" element={<DisplayProductsPage index={"muskarci"}></DisplayProductsPage>} />
          <Route path="zene" element={<DisplayProductsPage index={"zene"}></DisplayProductsPage>} />
          <Route path="deca" element={<DisplayProductsPage index={"deca"}></DisplayProductsPage>} />
          <Route path="ottodivision" element={<DisplayProductsPage index={"ottodivision"}></DisplayProductsPage>} />
          <Route path="proizvod/:name" element={<ProductOverviewRoute></ProductOverviewRoute>} />
          <Route path="korpa" element={<ShoppingCartRoute></ShoppingCartRoute>} />
          <Route path="porucivanje" element={<OrderingRoute></OrderingRoute>} />
          <Route path="*" element={<LandingPage />} />
        </Routes>
      </BrowserRouter>
    </DataContext.Provider>
  );
}

export default App;
