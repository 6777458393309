import CompanyPerk from "../components/ui/company_perks/company_perks";
import Footer from "../components/ui/footer/footer";
import Header from "../components/ui/header/header";
import ShoppingCart from "../components/ui/shopping_cart/shopping_cart";
import LoginDialog from "../components/ui/login_user/login_dialog";

const ShoppingCartRoute = () => {
    return (
        <>
            <Header></Header>
            <LoginDialog></LoginDialog>
            <ShoppingCart></ShoppingCart>
            <CompanyPerk></CompanyPerk>
            <Footer></Footer>
        </>
    )
}

export default ShoppingCartRoute;