import CompanyPerk from "../components/ui/company_perks/company_perks"
import Footer from "../components/ui/footer/footer"
import Header from "../components/ui/header/header"
import ProductOverview from "../components/ui/product_overview/product_overview"
import LoginDialog from "../components/ui/login_user/login_dialog"

const ProductOverviewRoute = () => {
    return (
        <>
            <Header></Header>
            <LoginDialog></LoginDialog>
            <ProductOverview></ProductOverview>
            <CompanyPerk></CompanyPerk>
            <Footer></Footer>
        </>
    )
}

export default ProductOverviewRoute