import Header from '../components/ui/header/header'
import BannerImages from '../components/ui/banner_images/banner_images'
import ProductStripe from '../components/ui/product_stripe/product_stripe'
import CompanyPerk from '../components/ui/company_perks/company_perks'
import Footer from '../components/ui/footer/footer'

const LandingPage = () => {

    return (
        <>
            <Header></Header>
            {/* <LoginDialog></LoginDialog> */}
            <BannerImages></BannerImages>
            <ProductStripe title={'НАЈПРОДАВАНИЈЕ'} dataIndex={1} id={'one'}></ProductStripe>
            <ProductStripe title={'НАЈНОВИЈЕ'} dataIndex={2} id={'two'}></ProductStripe>
            <CompanyPerk></CompanyPerk>
            <Footer></Footer>
        </>
    )
}

export default LandingPage