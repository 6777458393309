import { useEffect, useState } from "react";
import { CAPTCHA_URL } from "../config/constants";

export const useCaptcha = () => {
    const [captcha, setCaptcha] = useState({ token: '', image: '' });

    useEffect(() => {
        fetch(`${CAPTCHA_URL}/generateCaptcha`)
            .then((response) => response.json())
            .then((data) => {
                setCaptcha({
                    token: data.token,
                    image: `${CAPTCHA_URL}/images/${data.image}`
                });
            }).catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    function refreshCaptcha() {
        fetch(`${CAPTCHA_URL}/generateCaptcha`)
            .then((response) => response.json())
            .then((data) => {
                setCaptcha({
                    token: data.token,
                    image: `${CAPTCHA_URL}/${data.image}`
                });
            });
    }

    return [captcha, refreshCaptcha];
}