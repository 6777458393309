export const shouldUpdateProducts = (date) => {
    try {
        const now = new Date();
        const then = new Date(date);

        const differenceInMilliseconds = now - then;

        const sixtyMinutesInMilliseconds = 60 * 60 * 1000;

        return differenceInMilliseconds > sixtyMinutesInMilliseconds;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const getColorBlock = (color) => {
    switch (color) {
        case 'црна':
            return 'black';
        case 'бела':
            return 'white';
        case 'тегет':
            return 'navy';
        case 'црвена':
            return 'red';
        case 'плава':
            return 'blue';
    }
};

export const formatCurrency = (price) => {
    return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' РСД';
};