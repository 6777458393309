import { useEffect, useState } from "react";
import { fetchService } from "../services/fetch_service";
import { SERVER_URL } from "../config/constants";

export const useProduct = (data) => {
    const [product, setProduct] = useState(null);
    const [index, setIndex] = useState(0);
    const [image, setImage] = useState();

    useEffect(() => {
        try {
            if (typeof data === "string") {
                fetchProduct();
                return;
            }
            if (data == null) return;

            data.images = parseImages(data.images);
            data.price = data.price + '.00 РСД';
            data.colors = parseColors(data.colors);
            data.categories = parseCategories(data.categories);

            setProduct(data);
        } catch (error) {
            console.error(error);
        }
    }, [data]);

    const fetchProduct = async () => {
        try {
            let res = await fetchService().get(`${SERVER_URL}/get_product/${product}`);

            if (res.status === 200) {
                let fetched = res.product;

                fetched.images = parseImages(fetched.images);
                fetched.colors = parseColors(fetched.colors);
                fetched.categories = parseCategories(fetched.categories);
                fetched.price = fetched.price + '.00 РСД';

                setProduct(fetched);
            }
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        try {
            if (product == null) return;
            if (typeof product === "string") {
                fetchProduct();
                return;
            }
            setImage(product.images[index]);
        } catch (error) {
            console.error(error);
        }
    }, [product]);

    useEffect(() => {
        try {
            if (product == null) return;
            setImage(product.images[index]);
        } catch (error) {
            console.error(error);
        }
    }, [index]);

    const parseImages = (images) => {
        try {
            return images.split(',');
        } catch (error) {
            return [images];
        }
    }

    const parseColors = (colors) => {
        try {
            return colors.split(',');
        } catch (error) {
            return [colors];
        }
    }

    const parseCategories = (category) => {
        try {
            let categories = category.split(',');
            let categories_str = '';
            let counter = 0;

            categories.forEach(element => {
                switch (element) {
                    case '1':
                        counter === 0 ? categories_str += 'Мушкарци' : categories_str += ', ' + 'Мушкарци';
                        counter++;
                        break;
                    case '2':
                        counter === 0 ? categories_str += 'Жене' : categories_str += ', ' + 'Жене';
                        counter++;
                        break;
                    case '3':
                        counter === 0 ? categories_str += 'Деца' : categories_str += ', ' + 'Деца';
                        counter++;
                        break;
                    case '4':
                        counter === 0 ? categories_str += 'Otto-division' : categories_str += ', ' + 'Otto-division';
                        counter++;
                        break;
                    default:
                        counter === 0 ? categories_str += 'Мушкарци' : categories_str += ', ' + 'Мушкарци';
                        counter++;
                        break;
                }
            });

            return categories_str;
        } catch (error) {
            return category;
        }
    };

    const changeImage = () => {
        try {
            if (index < product.images.length - 1) {
                setIndex(index + 1);
            } else {
                setIndex(0);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const stopImage = () => {
        try {
            setIndex(0);
        } catch (error) {
            console.error(error);
        }
    };

    const backwards = () => {
        try {
            if (index > 0) {
                setIndex(index - 1);
            } else {
                setIndex(product.images.length - 1);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const forward = () => {
        try {
            if (index < product.images.length - 1) {
                setIndex(index + 1);
            } else {
                setIndex(0);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return { product, image, changeImage, stopImage, forward, backwards, setProduct };
};