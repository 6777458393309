import './mobile_side_menu.css'
import AnchorLink from '../../common/anchor_link/anchor_link'

const MobileMenu = () => {
    function closeLoginDialog() {
        console.log('asd')
        var menu = document.getElementById("mobileMenuContainerShow")
        menu.setAttribute("id", "mobileMenuContainerHide")
    }

    return (
        <>
            <div id='mobileMenuContainerHide'>
                <img style={{ marginBottom: "20px" }} src='../x.png' width={"20px"} height="20px" onClick={closeLoginDialog}></img>
                <AnchorLink text={"МУШКАРЦИ"} path={'/muskarci'} boldText={false} exe={closeLoginDialog}></AnchorLink>
                <AnchorLink text={"ЖЕНЕ"} path={'/zene'} boldText={false} exe={closeLoginDialog}></AnchorLink>
                <AnchorLink text={"ДЕЦА"} path={'/deca'} boldText={false} exe={closeLoginDialog}></AnchorLink>
                <AnchorLink text={"OTTO-DIVISION"} path={'/ottodivision'} boldText={true} exe={closeLoginDialog}></AnchorLink>
            </div>
        </>
    )
}

export default MobileMenu