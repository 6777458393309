import { useEffect, useState } from "react";
import { fetchService } from "../services/fetch_service";
import { SERVER_URL } from "../config/constants";
import { shouldUpdateProducts } from "../services/utils";

export const useProducts = (index) => {
    const [products, setProducts] = useState(null);

    useEffect(() => {
        getProducts();
    }, [index]);

    const getProducts = async () => {
        try {
            tryLocal();
        } catch (error) {
            console.error(error);
        }
    };

    const tryLocal = () => {
        try {
            const data = JSON.parse(localStorage.getItem(index));

            if (data && data.date) {
                if (shouldUpdateProducts(data.date)) {
                    tryRemote();
                } else {
                    setProducts(data.products);
                }
            } else {
                tryRemote();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const tryRemote = async () => {
        try {
            const data = await fetchService().post(`${SERVER_URL}/get_products`, { category: index });

            if (data.status === 200) {
                setProducts(data.products);
                localStorage.setItem(index, JSON.stringify({ products: data.products, date: new Date().toISOString() }));
            }
        } catch (error) {
            console.error(error);
        }
    };

    return { products };
};