import { useParams } from "react-router-dom"
import Header from "../../components/ui/header/header"
import LoginDialog from "../../components/ui/login_user/login_dialog"
import CompanyPerk from "../../components/ui/company_perks/company_perks"
import Footer from "../../components/ui/footer/footer"
import './info.css'

const Info = () => {
    const { route } = useParams()

    function getTitle() {
        switch (route) {
            case 'nacin-placanja-i-isporuka':
                return 'Начин плаћања и испорука'
            case 'reklamacije-i-povracaj-novca':
                return 'Рекламације и повраћај новца'
            case 'kako-naruciti':
                return 'Како наручити'
            case 'uslovi-koriscenja':
                return 'Услови коришћења'
            case 'politika-privatnosti':
                return 'Политика приватности'
            case 'prava-kupca':
                return 'Права купца'
        }
    }

    function getText() {
        switch (route) {
            case 'nacin-placanja-i-isporuka':
                return <p>Целокупан износ наруџбине купац плаћа готовином приликом преузимања пошиљке.
                    Доставу пошиљака врши курирска служба БЕКС(BEXExpress).
                    Очекивани рок испоруке је 7 радних дана из разлога што се производ израђује када нам стигне поруђбина.
                    <br></br><br></br>
                    На дан када курир преузме Вашу пошиљку, добићете е-маил обавештење да је пошиљка преузета.
                    Испорука се врши сваког радног дана између 8-17х.
                    На дан испоруке ћете добити позив/Вибер/СМС обавештење како бисте знали када да очекујете испоруку. </p>

            case 'reklamacije-i-povracaj-novca':
                return <p>Уколико желите да остварите рекламацију у вези са било којим производом који сте купили путем нашег сајта, погрешно обрачунатом ценом или неким другим недостатком, можете нас позвати на број 069 123 123, или нам пошаљите е-маил на podrska@ottoshop.rs.
                    <br></br><br></br>
                    Купац сам сноси трошкове повраћаја робе као и поновног слања у случају да рекламација није одобрена.
                    <br></br><br></br>
                    Када нас контактирате за рекламацију, дужни смо да Вам потврдимо пријем рекламације и саопштимо број под којим је заведена у нашој евиденцији.
                    Дужни смо да Вам најкасније у року од 8 дана одговоримо на рекламацију.
                    <br></br><br></br>
                    Важно је да знате да ћемо рок од 8 дана рачунати од тренутка када нам пошаљете робу на договорену адресу, јер тек по пријему робе можемо да утврдимо да ли постоји недостатак.
                    <br></br><br></br>
                    Уколико не прихватимо Вашу рекламацију бићете обавештени о томе путем телефона или е-маила.
                    <br></br><br></br>
                    Уколико Вам је стигла оштећена пошиљка, молимо Вас да нам најкасније у року од 24х од тренутка пријема производа пријавите евентуална оштећења настала у транспорту. Примљену робу сте дужни да проверите по преузимању од курира и ако је дошло до грешке, потрудићемо се да је у најкраћем могућем року исправимо. Уколико на амбалажи има видних трагова оштећења, саветујемо Вам да такву пошиљку не преузимате.

                    О свим детаљима везаним за несаобразност производа, те о начину на који се рекламација може решити, упознајте се у одељку Општи услови где су детаљније објашњена Ваша права прописана Законом о заштити потрошача.
                    <br></br><br></br>
                    У случају да нисте задовољни производом из било ког разлога, исти можете вратити у року од 14 дана од куповине. У овом случају купац сноси трошкове повраћаја производа.
                </p>

            case 'kako-naruciti':
                return <p>
                    Наруџбину можете реализовати на два начина:
                    <br></br><br></br>
                    1. Самостално путем сајта <br></br><br></br>
                    Пронађите жељени производ и кликните на дугме додај у корпу.<br></br><br></br>
                    Уколико производ има више опција неопходно је да изаберете све опције како би наставили са куповином.
                    У тренутку када додате производ у корпу на врху вебсајта у горњем десном углу ће се поред иконице корпе променити број производа у самој.<br></br> Кликом на иконицу корпе бићете преусмерени на нови екран са садржајем Ваше корпе као и финалном сумом.
                    Можете и директно без прегледања Ваше корпе наручити производ кликом на дугме Наручивање на страници производа, бићете преусмерени на екран за поручивање где је неопходно да унесете своје податке за доставу.
                    <br></br><br></br>
                    2. Уз помоћ подршке<br></br><br></br>
                    Жељене артикле можете поручити и позивом на број 066123123 или слањем мејла на prodaja@ottoshop.rs</p>

            case 'uslovi-koriscenja':
                return <p>
                    Ваш приступ и коришћење овог вебсајта подлеже доле дефинисаним условима коришћења. Приступом и коришћењем нашег вебсајта прихватате ове услове коришћења. Уколико не прихватате наше услове коришћења молимо Вас да напустите наш вебсајт.
                    <br></br><br></br>
                    Ottoshop интернет продавница може ревидирати и изменити услове коришћења у било којем тренутку, ажурирањем овог документа. Услови коришћења су обавезујући за потрошаче и саветујемо да се редвоно посећују, ради прегледа евентуалних измена.
                    <br></br><br></br>


                    <b>ВАШЕ ЛОЗИНКЕ И БЕЗБЕДНОСТ НАЛОГА</b>
                    <br></br>
                    Слажете се и разумете да сте Ви одговорни за чување поверљивости ваших лозинки које су у вези са вашим налозима са којима приступате нашим услугама. У складу са тим, слажете се да ћете једино Ви бити одговорни за активност на вашем налогу.
                    Ако приметите било какво неовлашћено коришћење своје лозинке или налога, слажете се да ћете одмах обавестити ottoshop интернет продавницу на мејл podrska@ottoshop.rs.
                    <br></br><br></br>
                    <b>ПРИВАТНОСТ КОРИСНИКА</b>
                    <br></br>
                    Запослени ottoshop.rs вебсајта поштују приватност својих корисника. Ипак, треба да знате да ако се од нас на основу закона, од стране надлежног суда или захтевом од стране других надлежних органа за спровођење закона буде захтевало да откријемо личне податке, ми можемо то да учинимo.
                    <br></br><br></br>
                    <b>ОДРИЦАЊЕ ОД ОДГОВОРНОСТИ</b>
                    <br></br>
                    оttoshop.rs пружа своје сервисе купцима у доброј намери. Ви се изричито слажете да је употреба сервиса сајта ottoshop.rs искључиво на Вашу одговорност и да се сајт ottoshop.rs не може сматрати за било какву директну или индиректну штету насталу његовим коришћењем.
                    Ми покушавамо да одржавамо софтвер и све осталче услуге на овом сајту исправним и безбедним, сајт може садржати грешке или пропусте за које ми не признајемо никакву, ни делимичну ни потпуну законску одговорност.

                    сајт оttoshop.rs задржава право промене садржаја без претходне најаве, укључујући и ову страну.
                    <br></br><br></br>
                    <b>ЗАКОНСКИ СПОРОВИ</b>
                    За сваки спор који настане поводом коришћења овог сајта или тумачења одредби услова коришћења примењиваће се закони Републике Србије и биће надлежни судови одговарајући судови на територији Републике Србије..
                </p>

            case 'politika-privatnosti':
                return <p>Политика приватности представља одељак наше странице у коме Вас ближе упознајемо и информишемо о мерама које предузимамо ради заштите података које прикупљамо о Вама, као и о начину на који податке прикупљамо, обрађујемо, чувамо, прослеђујемо или откривамо. Подаци се, према томе, обрађују искључиво на основу важец́их законских одредби Закона о заштити података о личности, Закона о заштити потрошача, Закона о електронској трговини, Европском регулативом о заштити података о личности (ГДПР) и добрим пословним обичајима.
                    У овој Политици приватности обавештавамо Вас о најважнијим аспектима обраде података на нашој веб страници и другим платформама које користимо. Наша Политика приватности објашњава како и под којим условима ottoshop.rs прикупља, користи, обрађује, чува и открива информације о вама. Изрази „ottoshop“, „ми“, „нас“, „заједница“ и „наш“ укључују ottoshop.rs и наше партнере. Изразе „клијент“, „корисник“, „члан“, „Ви“ и „Ваш“ користимо да означимо било коју особу која користи наше услуге, присуствује сродним догађајима, прима информације од нас, укључујуц́и било коју организацију или особу која користи наше услуге. Ова Политика приватности односи се на обраду података о корисницима и другим појединцима које прикупљамо када користите наше услуге и платформе, што подразумева било који догађај, радионицу, веб страницу, апликацију или услуге које нудимо или ситуацију када комуницирате са нама. Ова Политика приватности се не односи на информације које можете пружити трец́им лицима с којима можете делити информације о себи.
                    <br></br><br></br>
                    Прикупљање информација

                    Информације о вама прикупљамо када:<br></br><br></br>

                    резервишите пакет или услугу;<br></br>
                    купујете наше производе<br></br>
                    се региструјете или користите било коју другу услугу коју пружамо;<br></br>
                    комуницирате са нама.<br></br>
                    <br></br>
                    Информације које нам пружате прикупљамо директно. На пример, прикупљамо информације које наведете када креирате свој профил, попуњавате образац или комуницирате са нама. Врсту информација које можемо прикупљати укључују Ваше пуно име, лозинку, мејл адресу, поштанску адресу, број телефона. Ове податке обрађујемо како бисмо управљали нашим односом и пружили Вам могуцћност уклапања и задовољили ваше потребе.
                    <br></br> <br></br>
                    Користимо колачиће на чију употребу сте дали изричиту сагласност на почетку коришћења наше странице.
                    <br></br> <br></br>
                    Информације које прикупљају колачицћи: Ми прикупљамо информације помоћу колачића. Могућа је употреба колачића попут колачићи сесије или трајни колачићи.
                    <br></br> <br></br>
                    Упознајемо Вас да можете да одаберете и опцију да искључите колачиће, то радите у подешавањима интернет претраживача. Онемогућавање колачића може ограничити функционалност употребе наше странице .
                    <br></br> <br></br>
                    Информације о Вама користимо да бисмо: обављали нашу делатност, пословали унутар, побољшавали, промовисали и заштитили, нашу услугу и платформу; комуницирали са Вама.

                    Информације о Вама можемо користити у разне сврхе које се односе на наш рад наше услуге, продаје робе, укључујуцћи:
                    <br></br>
                    Пружање, одржавање и унапређење наше услуге и платформе, укључујуц́и обраду трансакција, развој нових производа и услуга и управљање учинком;
                    <br></br>
                    Информације о Вама можемо прикупљати и користити у разне сврхе које се односе на комуникацију са Вама, укључујуцћи: Одговоре на Ваше коментаре, питања и захтеве и пружање корисничке услуге; комуникацију с Вама, у складу са подешавањима Вашег налога, о производима, услугама и догађајима које нудимо ми и други, како бисмо пружили вести и информације за које мислимо да ће Вас занимати, како бисмо спровели анкете онлајн и како бисмо на други начин комуницирали с Вама и послали Вам техничка обавештења, унапређења, безбедносна упозорења и подршку и административне поруке. Информације о Вама можемо користити у разне друге сврхе, укључујући: Обезбеђивање садржаја, функција или спонзорства која се подударају са Вашим профилом или интересовањима и извршавање било које друге активности која Вам је описана у време када смо прикупљали информације.
                    <br></br><br></br>
                    Наш правни основ за прикупљање и употребу горе описаних информација зависиц́е од врсте информација и специфичног контекста у којем их прикупљамо. Све информације прикупљамо у складу са важећим прописима Републике Србије који су усаглашени са европском регулативом и међународним правом.

                    Такође обрађујемо информације о Вама тамо где је то у нашим легитимним интересима али увек водимо рачуна да наши интереси не надјачају Ваша права. У неким случајевима можемо имати и законску обавезу да прикупљамо информације о Вама.

                    Претежни део информација прикупљамо искључиво ради обављања наше делатности и да бисмо спровели ставке из уговора и извршили уговор. Можемо приступити, сачувати и открити информације о Вама трећим странама ако верујемо да је откривање у складу са важецћим законом, прописом, правним поступком или ревизијом или га захтева надлежни државни орган. Такође можемо открити информације о Вама ако верујемо да су Ваши поступци у супротности са нашим Условима и одредбама или повезаним смерницама и политикама, или ако је то неопходно ради заштите права, имовине или безбедности или спречавања превара или злоупотребе. Информације о Вама можемо открити на захтев надлежних државних органа уз оправдан интерес заштите националне безбедности, одбране, јавне безбедности, гоњење учинилаца кривичних дела и сл. а све у складу са позитивним прописима.

                    У разумним оквирима се трудимо да заштитимо Ваше личне податке и применили смо техничке и организационе мере у складу са најбољом индустријском праксом. Ове мере предострожности односе се на заштиту ради превенције неовлашцћеног, незаконитог или случајног приступа, обраде, губитка, употребе и манипулације. Не можемо гарантовати да цће ове контролне мере пружити апсолутну сигурност. Имајте на уму да стога не преузимамо никакву одговорност за откривање информација због неузрокованих грешака у преносу података и / или неовлашцћеног приступа трецћих лица. (на пример: хаковање налога за мејл или телефона, пресретање факсова итд.).
                    <br></br><br></br>
                    Опште гледано, задржавамо личне податке које прикупљамо од Вас до краја нашег пословног односа или до краја законски захтеваног периода задржавања. Ако сте клијент, потенцијални клијент или претходни клијент, чувамо Ваше личне податке за маркетинг док не уложите приговор или повучете сагласност.
                    <br></br><br></br>

                    С времена на време можемо модификовати ову Политику приватности. Када то учинимо, обавестицћемо Вас објављивањем најновије верзије. Ако направимо било какву значајну промену ових смерница, пружицћемо Вам додатно обавештење, на пример, слањем мејла.
                    <br></br>
                    Можете се претплатити на наш билтен или други рекламни производ путем наше веб странице. За ово су нам потребне Ваша мејл адреса и изјава да се слажете да примате исти.
                    <br></br>
                    Након што се пријавите послацћемо Вам мејл са линком за потврду регистрације.

                    Пријаву можете отказати у било ком тренутку. Отказивање пошаљите на следецћу мејл адресу: podrska@ottoshop.rs

                    Одмах ћемо избрисати Ваше податке које сте нам пружили приликом пријаве

                    Без обзира на Ваше држављанство или место пребивалишта, између осталих права и права предвиђених домицилним законима (Законима Републике Србије),  додељујемо Вам сва права која се односе на Ваше личне податке која Европска општа уредба о заштити података предвиђа за субјекте података. Такође ова права укључују:
                    <br></br><br></br>
                    <b>ПРАВО НА ПРИСТУП ВАШИМ ПОДАЦИМА</b><br></br>
                    Имате право да затражите приступ личним подацима које смо прикупили о Вама и да затражите одређене информације и увиде у то како третирамо, делимо, чувамо и на други начин обрађујемо ове личне податке. Наведено можете учинити подношењем захтева на маил адресу pdorska@ottoshop.rs .
                    <br></br><br></br>
                    <b>ПРАВО НА ПРОМЕНУ  ИСПРАВКУ И ДОПУНУ ПОДАТАКА</b>
                    <br></br>
                    У случају да су лични подаци које чувамо о Вама нетачни, непрецизни или застарели, имате право да захтевате да исправимо ове грешке. Да бисте остварили ово право, довољно је само указати на грешке у мејл поруци на маил адресу podrska@ottoshop.rs.

                    ПРАВО НА БРИСАЊЕ („ПРАВО ДА СЕ ЗАБОРАВИ“) / ПОВЛАЧЕЊЕ САГЛАСНОСТИ, ОГРАНИЧЕЊЕ ОБРАДЕ
                    <br></br>
                    Имате право да затражите брисање прикупљених података и повучете сагласност за прикупљање, наведено можете учинити подношењем писменог захтева на маил адресу prodaja@ottoshop.rs. Уколико то учините, одмах ћемо уништити прикупљене податке и нећемо прикупљати нове. Имате право да ограничите обраду, уколико оспоравате тачност, законитост обраде, уколико сте поднели приговор на обраду или је престала сврха обраде. Ово укључује захтевање од нас да ограничимо обраду Ваших података или преносивости Ваших података. Наведено можете учинити на маил адресу podrska@ottoshop.rs .
                    <br></br><br></br>
                    <b>ПРАВО ДА ОТКАЖЕТЕ ПРИЈЕМ НАШЕГ ОГЛАШАВАЊА КОЈЕ ВАМ У СВАКОМ ТРЕНУТКУ ШАЉЕМО.</b>
                    <br></br>
                    Ово право можете остварити избором опције „одјава“ у мејловима поводом оглашавања које Вам шаљемо. Поред тога, своје поставке мејлова можете ажурирати променом подешавања на налогу.
                    <br></br><br></br>
                    <b>ПРАВО НА ПРИГОВОР</b>
                    <br></br>
                    Имате право да улажете приговор на обраду података о Вама и то можете учинити на маил адресу podrska@ottoshop.rs . Такође на исту маил адресу можете упутити питања, коментаре и захтеве који се односе на ову политику приватности. Вашу жалбу, приговор, коментар, питање, захтев ми ћемо истражити без одлагања.
                    <br></br><br></br>
                    <b>ПРАВО НА ЖАЛБУ ОРГАНУ ЗА ЗАШТИТУ ПОДАТАКА ПОВОДОМ НАШЕГ ПРИКУПЉАЊА И КОРИШЦ́ЕЊА ВАШИХ ИНФОРМАЦИЈА.</b>
                    <br></br>
                    Уколико сматрате да је обрада података о Вашој личности извршена противзаконито имате право да поднесете притужбу поверенику за информације од јавног значаја и заштиту података о личности као и право на судску заштиту против одлуке повереника. Имате право и на подношење тужбе суду ради судске заштите права, овим се не дира у право на покретање других поступака попут управних и др.
                    <br></br><br></br>
                    <b>МЕРОДАВНО ПРАВО/НАДЛЕЖНОСТ</b><br></br>

                    Овом Политиком приватности њеним условима управљацће се и тумачицће се у складу са законима о заштити података у Републици Србији и другим законским и подзаконским актима Републике Србије. Надлежност за решавање правних спорова који се тичу ове политике података, њеног тумачења и нашег руковања Вашим личним подацима припада надлежним судовима и другим органима Републике Србије.
                    <br></br><br></br>
                    <b>КОНТАКТИРАЈТЕ НАС</b><br></br>

                    Ако желите да остварите своја права, имате питања у вези са овом политиком, желите да нас контактирате или знате додатне детаље о томе како користимо личне податке, контактирајте нас електронским путем на: podrska@ottoshop.rs

                    Ова политика приватности је саставни део УСЛОВА КОРИШЋЕЊА. Прихватањем УСЛОВА КОРИШЋЕЊА и интернет продаје изражавате своју сагласност са овом политиком.</p>

        }
    }

    return (
        <>
            <Header></Header>
            <LoginDialog></LoginDialog>
            <div id="information-text">
                <h1>{getTitle()}</h1>
                <p>{getText()}</p>
            </div>
            <CompanyPerk></CompanyPerk>
            <Footer></Footer>
        </>
    )
}

export default Info