import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../common/loader/loader';
import './product_overview.css';
import { SERVER_URL } from '../../../config/constants';
import { useProduct } from '../../../hooks/use_product';
import { useCart } from '../../../hooks/use_cart';
import { getColorBlock } from '../../../services/utils';

const ProductOverview = ({ productProp }) => {
    const { product, setProduct, backwards, forward, image } = useProduct();
    const { cart, getCart, addToCart } = useCart();
    const [selectedColor, setSelectedColor] = useState(false);
    const [quantity, setQuantity] = useState('1');
    const [selectedSize, setSelectedSize] = useState(false);
    const [error, setError] = useState("");
    const [confirmation, setConfirmation] = useState("");
    const [relocateError, setRelocateError] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (productProp) setProduct(productProp);
        else setProduct(location.pathname.split('/')[2]);
    }, []);

    function getColorOptions() {
        if (product.colors.length == 0) return;
        return product.colors.map((color, index) => {
            if (color.includes('-')) {
                let color_split = color.split('-');
                return (
                    <div
                        id='color-option'
                        key={index + 20}
                        class={color}
                        onClick={() => { selectColor(color) }}
                    >
                        {
                            color_split.map((color, index) => {
                                return (
                                    <div
                                        key={index + 77}
                                        style={{ height: "50px", width: "100%", backgroundColor: getColorBlock(color) }}
                                    >

                                    </div>
                                )
                            })
                        }
                    </div>
                )
            } else {
                return (
                    <div
                        id='color-option'
                        key={index + 20}
                        class={color}
                        onClick={() => { selectColor(color) }}
                        style={{ backgroundColor: getColorBlock(color) }}
                    >

                    </div>
                )
            }
        });
    }

    function selectSize(size) {
        let element_id = ['S', 'M', 'L', 'XL', 'XXL', 'XXXL']

        element_id.forEach((element, index) => {
            let element_selected = document.getElementById(element)

            if (element == size) {
                setSelectedSize(size)

                if (element_selected.style.border === '3px solid gray') {
                    element_selected.style.border = '3px solid rgb(85, 116, 255)'
                }
            }
            else {
                element_selected.style.border = '3px solid gray'
            }
        });
    }

    function selectColor(color) {
        let element = document.getElementsByClassName(color);
        element[0].style.border = '3px solid rgb(85, 116, 255)';

        for (let i = 0; i < product.colors.length; i++) {
            if (product.colors[i] != color) {
                let option = document.getElementsByClassName(product.colors[i]);
                option[0].style.border = '3px solid rgb(218, 218, 218)';
            }
        }

        setSelectedColor(color);
    }

    function addQuantity(event) {
        setQuantity(event.target.value);
    }

    function addProductToCart() {
        setError('');

        if (product.colors.length > 0 && selectedColor == false) {
            setError('Морате изабрати боју!');
            return;
        }

        if (selectedSize == false) {
            setError('Морате изабрати величину!');
            return;
        }

        if (quantity == '' || quantity == '0') {
            setError('Морате унети количину!');
            return;
        }

        let item = {
            name: product.name,
            url: product.url,
            price: product.price,
            quantity: quantity,
            size: selectedSize,
            color: selectedColor == false ? '' : selectedColor,
            images: product.images,
        };

        addToCart(item);
        setConfirmation('Производ је додат у корпу!');
    }

    function redirect() {
        setError('');

        if (cart.length > 0) navigate('/porucivanje');
        else setError('Корпа је празна!');
    }

    if (product == null || typeof product !== "object") {
        return <div id='loading-div'>
            <Loader elHeight="50px"></Loader>
        </div>
    } else {
        return (
            typeof product !== "object" ?
                <div id='loading-div'>
                    <Loader elHeight="50px"></Loader>
                </div>
                :
                <div id='product-preview-container'>
                    <div id='product-preview'>
                        <div id='product-preview-image'>
                            <img src={`/products/${image}.png`} loading='lazy'></img>
                            <button id='swipe-left' onClick={backwards}>
                                <p>{'<'}</p>
                            </button>
                            <button id='swipe-right' onClick={forward}>
                                <p>{'>'}</p>
                            </button>
                        </div>
                        <div id='product-order-details'>
                            <p id='product-preview-name'>{product.name}</p>
                            <p id='product-preview-category'>Категорија: {product.categories}</p>
                            <p id='product-preview-price'>Цена: <b>{product.price}</b></p>
                            <div id='description-container'>
                                {product.description}
                            </div>
                            {
                                product.colors.length > 0 ?
                                    <>
                                        <p id='color-select-text'>Изаберите боју:</p>
                                        <div id='color-picker'>
                                            {
                                                getColorOptions()
                                            }
                                        </div>
                                    </>
                                    : null
                            }
                            <p id='color-select-text'>Изаберите величину:</p>
                            <div id='sizes-container'>
                                <div id={"S"} style={{ border: '3px solid gray' }} class='active-size' key={1} onClick={() => selectSize("S")}>{"S"}</div>
                                <div id={"M"} style={{ border: '3px solid gray' }} class='active-size' key={2} onClick={() => selectSize("M")}>{"M"}</div>
                                <div id={"L"} style={{ border: '3px solid gray' }} class='active-size' key={3} onClick={() => selectSize("L")}>{"L"}</div>
                                <div id={"XL"} style={{ border: '3px solid gray' }} class='active-size' key={4} onClick={() => selectSize("XL")}>{"XL"}</div>
                                <div id={"XXL"} style={{ border: '3px solid gray' }} class='active-size' key={5} onClick={() => selectSize("XXL")}>{"XXL"}</div>
                                <div id={"XXXL"} style={{ border: '3px solid gray' }} class='active-size' key={6} onClick={() => selectSize("XXXL")}>{"XXXL"}</div>
                            </div>
                            <p id='color-select-text'>Количина:</p>
                            <input id='ammount-selector' type={'number'} defaultValue='1' placeholder="Количина" min='1' onChange={(e) => addQuantity(e)}></input>
                            {
                                error == '' ? null : <p style={{ color: 'red' }}>{error}</p>
                            }
                            {
                                confirmation == '' ?
                                    null
                                    :
                                    <div style={{
                                        width: "100%",
                                        boxSizing: "border-box",
                                        padding: "5px",
                                        backgroundColor: "rgba(43, 255, 71, 0.849)",
                                        marginTop: "10px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                        <p style={{ color: "black" }}>{confirmation}</p>
                                    </div>
                            }
                            <button id='add-to-cart' onClick={addProductToCart}>У Корпу</button>
                            <button id='order-button-overview' onClick={() => redirect()}>Наручивање</button>
                            {relocateError == false ? null : <p style={{ color: 'red' }}>{relocateError}</p>}
                        </div>
                    </div>
                </div>
        )
    }
}

export default ProductOverview