import { useContext, useEffect, useState } from "react";
import DataContext from "../context/data_context";

export const useCart = () => {
    const { cart, setCart } = useContext(DataContext);
    const [coupon, setCoupon] = useState(null);
    const [data, setData] = useState({
        total: 0,
        free: false,
        discount: 0
    });

    useEffect(() => {
        getCart();
    }, []);

    const getCart = () => {
        try {
            const cartData = localStorage.getItem('cart');
            if (cartData) {
                const cart = JSON.parse(cartData);
                setCart(cart);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const addToCart = (product) => {
        try {
            if (!product.quantity || !product.size) {
                console.error('Neispravni podaci proizvoda');
                return;
            }

            for (var item of cart) {
                if (item.url === product.url && item.size === product.size) {
                    item.quantity = parseInt(item.quantity) + parseInt(product.quantity);
                    setCart([...cart]);
                    return;
                }
            }

            setCart([...cart, product]);
        } catch (error) {
            console.error(error);
        }
    };

    const addQuantity = (product) => {
        try {
            for (var item of cart) {
                if (item.url === product.url && item.size === product.size) {
                    item.quantity = parseInt(item.quantity) + 1;
                    setCart([...cart]);
                    return;
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const removeQuantity = (product) => {
        try {
            for (var item of cart) {
                if (item.url === product.url && item.size === product.size) {
                    item.quantity = parseInt(item.quantity) - 1;
                    setCart([...cart]);
                    return;
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const removeProduct = (product) => {
        try {
            const newCart = cart.filter((item) => item.url === product.url && item.size !== product.size);
            setCart(newCart);
        } catch (error) {
            console.error(error);
        }
    };

    const setQuantity = (product) => {
        try {
            for (var item of cart) {
                if (item.url === product.url && item.size === product.size) {
                    item.quantity = parseInt(product.quantity);
                    setCart([...cart]);
                    return;
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const applyCoupon = (discount) => {
        setCoupon(discount);
    };

    const emptyCart = () => {
        try {
            setCart([]);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));

        let total = 0;
        let free = false;

        if (cart.length === 0) {
            setData({
                total: 0,
                free: false,
                discount: 0
            });
            return;
        }
        for (var item of cart) {
            total += parseInt(item.price) * parseInt(item.quantity);
        }

        let discount = 0;
        if (total > 5000) free = true;

        let hold = total;
        if (coupon) {
            total = total - (total * coupon / 100);
        }

        discount = hold - total;

        total = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' РСД';

        setData({
            total: total,
            free: free,
            discount: discount,
        });
    }, [cart, coupon]);

    return {
        cart,
        data,
        getCart,
        addToCart,
        addQuantity,
        removeQuantity,
        removeProduct,
        setQuantity,
        applyCoupon,
        emptyCart
    };
};