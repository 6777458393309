import { useCart } from '../../../hooks/use_cart'
import ShoppingCartItem from '../../common/shopping_cart_item/shopping_cart_item';
import './shopping_cart.css'
import { Link } from 'react-router-dom';

const ShoppingCart = () => {
    const { cart, data } = useCart();

    return (
        <>
            {
                cart.length === 0 ?
                    <div id='cart-container'>
                        <div id='cart-title'>
                            <p>КОРПА</p>
                        </div>
                        <div id='center-cart' style={{ height: '350px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: '1vw' }}>
                            <p>Нема производа у корпи</p>
                        </div>
                    </div>
                    :
                    <div id='cart-container'>
                        <div id='cart-title'>
                            <p>КОРПА</p>
                        </div>
                        <div id='shopping-cart'>
                            {
                                cart.map((product, index) => {
                                    return <ShoppingCartItem product={product} key={index + 30}></ShoppingCartItem>
                                })
                            }
                        </div>
                        <div id='total-sum-price'>
                            <p><b>УКУПНО ЗА НАПЛАТУ: {data.total}</b></p>
                            <p><b>{data.free ? "ПОШТАРИНА ЈЕ БЕСПЛАТНА" : "ПОШТАРИНА СЕ НАПЛАЋУЈЕ"}</b></p>
                        </div>
                        <div id='order-button-container'>
                            {
                                cart.length > 0 ?
                                    <Link to={'/porucivanje'} style={{ textDecoration: 'none' }}>
                                        <button id='order-button'>
                                            ПОРУЧИВАЊЕ
                                        </button>
                                    </Link>
                                    :
                                    null
                            }
                        </div>
                    </div>
            }
        </>
    )
}

export default ShoppingCart