import './banner_images.css'
import { useBanners } from '../../../hooks/use_banners'
import Loader from '../../common/loader/loader'
import { Link } from 'react-router-dom'

const BannerImages = () => {
    const [banner, index, next, previous, numberOfBanners] = useBanners();

    if (banner == null || banner[0] == null) {
        return (
            <div id="banner-images-container">

            </div>
        )
    } else {
        return (
            <div id="banner-images-container">
                <Link to={banner ? `/proizvod/${banner[1]}` : ""}>
                    <div id='banner-image' style={{ height: numberOfBanners == 0 ? "300px" : "auto" }}>
                        {
                            numberOfBanners > 0 && banner[0] != null ?
                                <img src={banner[0]} width="auto"></img>
                                :
                                <Loader elHeight={"50px"}></Loader>
                        }
                        {numberOfBanners > 1 ?
                            <>
                                <div id='banner-middle-dots' >
                                    {
                                        banner.map((b, i) => {
                                            return <div key={i} className={i === index ? 'banner-dot-active' : 'banner-dot'}></div>
                                        })
                                    }
                                </div>
                            </>
                            :
                            null}
                    </div>
                </Link>
            </div >
        )
    }
}

export default BannerImages